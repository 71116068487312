<template>
    <div class="reviewMain" ref='sendRecord' @scroll="scroll">
        <div>
            <div>
                <el-form :inline="true" class="demo-form-inline"  >
                    <el-form-item label="单位:">
                        <el-select
                            v-model="search.institutionId"
                            size="medium" clearable filterable
                            @change="handleInstitution"
                            placeholder="请选择单位"
                            class="form-line-item"
                            ref="selectInstitution"
                            @visible-change="isShowSelectOptions"
                        >
                            <el-option v-for="(item,index) in institutionList"
                                       :key='index'
                                       :label="item.institutionName"
                                       :value="item.institutionId"></el-option>

                        </el-select>
                        <!-- <el-select v-model="search.institutionId" size="medium" filterable remote reserve-keyword
                            placeholder="请输入单位" :remote-method="remoteMethod" class="form-line-item"
                            @change="handleInstitution" :loading="selectLoading">
                            <el-option v-for="(item,index) in institutionList" :key='index'
                                :label="item.institutionName" :value="item.institutionId"></el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="批次:">
                        <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                            class="form-line-item" :disabled='batchShow'
                                   ref="selectBatchId"
                                   @visible-change="isShowSelectOptions"
                        >
                            <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                :value="item.batchId"></el-option>

                        </el-select>
                    </el-form-item>
                    <el-form-item label="论文题目:">
                        <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                            class="form-line-item">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="编号:">
                      <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
                      </el-input>
                    </el-form-item>
<!--                    <el-form-item label="学生:">-->
<!--                      <el-input placeholder="请输入学生姓名" v-model="search.student" size='medium' class="form-line-item">-->
<!--                      </el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="学科:">
                      <el-input placeholder="请输入学科" v-model="search.major" size='medium' class="form-line-item">
                      </el-input>
                    </el-form-item>
                    <!-- <el-form-item label="学科:">
                        <el-input placeholder="请输入学科" v-model="search.major" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item> -->
                </el-form>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="学号:">
                      <el-input placeholder="请输入学生学号" v-model="search.studentNum" size='medium' class="form-line-item">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="导师:">
                      <el-input placeholder="请输入导师姓名" v-model="search.supervisor" size='medium' class="form-line-item">
                      </el-input>
                    </el-form-item>
                  <el-form-item label="学生:">
                    <el-input placeholder="请输入学生姓名" v-model="search.student" size='medium' class="form-line-item">
                    </el-input>
                  </el-form-item>
                    <el-form-item label="状态:">
                        <el-select v-model="search.state" size="medium" clearable placeholder="请选择状态"
                            class="form-line-item"
                                   ref="selectState"
                                   @visible-change="isShowSelectOptions"
                        >
                            <el-option label="待匹配" :value="4"></el-option>
                            <el-option label="待送审" :value="0"></el-option>
                            <el-option label="送审中" :value="1"></el-option>
                            <el-option label="评审中" :value="2"></el-option>
                            <el-option label="已完成" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="学位类型:">
                        <el-input placeholder="请输入学位类型" v-model="search.paperType" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item> -->

                    <el-form-item label="送审方式:">
                        <el-select v-model="search.sendingType" size="medium" clearable placeholder="请选择送审方式"
                            class="form-line-item"
                                   ref="selectSendingType"
                                   @visible-change="isShowSelectOptions"
                        >
                            <el-option label="人工送审" :value="3"></el-option>
                            <el-option label="自动送审" :value="1"></el-option>
                            <el-option label="人工干预" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-select v-model="search.haveNote" size="medium" clearable placeholder="请选择备注状态"
                                 class="form-line-item"
                                 ref="selectHaveNote"
                                 @visible-change="isShowSelectOptions">
                        <el-option label="带备注" :value="1"></el-option>
                        <el-option label="无备注" :value="0"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item style="margin-left:10px;">
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                            搜索
                        </el-button>
                        <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <!-- <el-button type="success" size="small" @click="sendEmailHandle" icon="el-icon-position"
                        style="margin-right:10px;">发送邀审信息</el-button> -->
                    <el-popconfirm title="是否发送？" @confirm='sendEmailHandle'>
                        <el-button slot="reference" type="success" size="small" icon="el-icon-position"
                            style="margin-right:10px;">发送邀审信息</el-button>
                    </el-popconfirm>
                    <!-- <el-button type="success" size="small" @click="matchHandle">匹配专家</el-button> -->
                    <el-popover ref="popover1" placement="right" trigger="click" >
                        <el-form label-position="left" >
                            <el-form :inline="true" class="demo-form-inline"> 
                                <el-form-item label="评审意愿" label-width="70px">
                                    <el-checkbox-group v-model="accept" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审责任心" label-width="90px">
                                    <el-checkbox-group v-model="responsibility" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="评审速度" label-width="70px">
                                    <el-checkbox-group v-model="frequency" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审奇异性" label-width="90px">
                                    <el-checkbox-group v-model="singularity" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="评审质量" label-width="70px">
                                    <el-checkbox-group v-model="quality" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item>
                                <!-- <el-form-item label="评审均值" label-width="90px">
                                    <el-checkbox-group v-model="mean" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                        <el-checkbox-button label="D">D</el-checkbox-button>
                                        <el-checkbox-button label="E">E</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="严格程度" label-width="70px">
                                <el-input placeholder="选填，请输入分数区间：80-90" style="width:230px;" v-model="avgScore" size='medium'
                                class="form-line-item">
                                </el-input>
                              </el-form-item>
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                    <el-form-item label="专家库" label-width="70px">
                                      <el-checkbox-group v-model="checkedExperts" size="small" @change="handleExpertChange">
                                        <el-checkbox-button :label="0">活跃专家</el-checkbox-button>
                                        <el-checkbox-button :label="1">备用专家</el-checkbox-button>
                                      </el-checkbox-group>
                                    </el-form-item>
<!--                                <el-form-item label="专家库"  label-width="100px">-->
<!--                                    <el-radio-group v-model="isSpare" size="small">-->
<!--                                        <el-radio-button :label="0">活跃专家</el-radio-button>-->
<!--                                        <el-radio-button :label="1">备用专家</el-radio-button>-->
<!--                                        <el-radio-button :label="2">全部专家</el-radio-button>-->
<!--                                    </el-radio-group>-->
<!--                                </el-form-item>-->
                                <!-- <el-form-item label="评审标准差" label-width="90px">
                                    <el-checkbox-group v-model="standard_deviation" size="small">
                                        <el-checkbox-button label="A">A</el-checkbox-button>
                                        <el-checkbox-button label="B">B</el-checkbox-button>
                                        <el-checkbox-button label="C">C</el-checkbox-button>
                                        <el-checkbox-button label="D">D</el-checkbox-button>
                                        <el-checkbox-button label="E">E</el-checkbox-button>
                                    </el-checkbox-group>
                                </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="匹配学科"  label-width="90px">
                                    <el-radio-group v-model="isSecondMajor" size="small">
                                        <el-radio-button :label="0">一级学科</el-radio-button>
                                        <el-radio-button :label="1">二级学科</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                            <!-- <el-form :inline="true" class="demo-form-inline">
                                <el-form-item label="匹配模式"  label-width="90px">
                                    <el-radio-group v-model="isSecojor" size="small">
                                        <el-radio-button :label="3">文本匹配</el-radio-button>
                                        <el-radio-button :label="4">关联匹配</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>

                            </el-form> -->
<!--                            <el-form :inline="true" class="demo-form-inline">-->
<!--                              <el-form-item label="最低分数:">-->
<!--                                <el-input placeholder="请输入快速匹配最低分数"  v-model="basicScore" size='small' >-->
<!--                                </el-input>-->
<!--                              </el-form-item>-->
<!--                            </el-form>-->

                            <el-form-item label="" label-width="80px">
<!--                                    <el-button type="success" size="small" @click="matchHandle">开始匹配</el-button>-->
                                <el-popconfirm title="是否开始匹配？" @confirm='confirmFineMatch'>
                                  <el-button style="margin:0 10px;" slot="reference" type="success"  size="small" >开始匹配
                                  </el-button>
                                </el-popconfirm>
<!--                                <el-popconfirm title="是否快速匹配？" @confirm='confirmQuickMatch'>-->
<!--                                  <el-button style="margin:0 10px;" slot="reference" type="success" size="small" >快速匹配-->
<!--                                  </el-button>-->
<!--                                </el-popconfirm>-->
                            </el-form-item>
                        </el-form>
                        <el-button slot="reference" type="success" icon="el-icon-discover" style="margin-right:10px;"
                            size="small">匹配专家</el-button>
<!--                        <el-button slot="reference" type="success" icon="el-icon-discover" style="margin-right:10px;"-->
<!--                            size="small" @click="resetBasicScore">匹配专家</el-button>-->
                    </el-popover>
                    <el-popconfirm title="是否同批复匹？" @confirm='acceptedMatchHandle'>
                        <el-button slot="reference" type="success" size="small"  icon="el-icon-discover" class="custom-margin">同批复匹</el-button>
                    </el-popconfirm>
                    <el-popconfirm title="是否导出送审记录？" @confirm='exportSending'>
                      <el-button slot="reference" type="success" size="small" icon="el-icon-download" >导出送审记录
                      </el-button>
                    </el-popconfirm>
<!--                    <el-popover ref="popover2" placement="right" trigger="click" >-->
<!--                            <el-form label-position="left" >-->
<!--                                <el-form :inline="true" class="demo-form-inline">-->
<!--                                <el-form-item label="评审意愿" label-width="70px">-->
<!--                                    <el-checkbox-group v-model="autoSending.accept" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item>-->
<!--                                &lt;!&ndash; <el-form-item label="评审责任心" label-width="90px">-->
<!--                                    <el-checkbox-group v-model="responsibility" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item> &ndash;&gt;-->
<!--                            </el-form>-->
<!--                            <el-form :inline="true" class="demo-form-inline">-->
<!--                                <el-form-item label="评审速度" label-width="70px">-->
<!--                                    <el-checkbox-group v-model="autoSending.frequency" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item>-->
<!--                                &lt;!&ndash; <el-form-item label="评审奇异性" label-width="90px">-->
<!--                                    <el-checkbox-group v-model="singularity" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item> &ndash;&gt;-->
<!--                            </el-form>-->
<!--                            <el-form :inline="true" class="demo-form-inline">-->
<!--                                <el-form-item label="评审质量" label-width="70px">-->
<!--                                    <el-checkbox-group v-model="autoSending.quality" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item>-->
<!--                                &lt;!&ndash; <el-form-item label="评审均值" label-width="90px">-->
<!--                                    <el-checkbox-group v-model="mean" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="D">D</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="E">E</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item> &ndash;&gt;-->
<!--                            </el-form>-->
<!--                            <el-form :inline="true" class="demo-form-inline">-->
<!--                                <el-form-item label="启动备用专家"  label-width="100px">-->
<!--                                    <el-radio-group v-model="autoSending.isSpare" size="small">-->
<!--                                        <el-radio-button :label="1">是</el-radio-button>-->
<!--                                        <el-radio-button :label="0">否</el-radio-button>-->
<!--                                    </el-radio-group>-->
<!--                                </el-form-item>-->
<!--                                &lt;!&ndash; <el-form-item label="评审标准差" label-width="90px">-->
<!--                                    <el-checkbox-group v-model="standard_deviation" size="small">-->
<!--                                        <el-checkbox-button label="A">A</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="B">B</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="C">C</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="D">D</el-checkbox-button>-->
<!--                                        <el-checkbox-button label="E">E</el-checkbox-button>-->
<!--                                    </el-checkbox-group>-->
<!--                                </el-form-item> &ndash;&gt;-->
<!--                            </el-form>-->
<!--                            <el-form :inline="true" class="demo-form-inline">-->
<!--                                <el-form-item label="匹配学科"  label-width="90px">-->
<!--                                    <el-radio-group v-model="autoSending.isSecondMajor" size="small">-->
<!--                                        <el-radio-button :label="0">一级学科</el-radio-button>-->
<!--                                        <el-radio-button :label="1">二级学科</el-radio-button>-->
<!--                                    </el-radio-group>-->
<!--                                </el-form-item>-->
<!--                            </el-form>-->
<!--                                <el-form-item label="天数"  label-width="55px">-->
<!--                                    <el-input-number v-model="autoSending.days" size="small" :min="1" label="请输入天数"></el-input-number>-->
<!--                                </el-form-item>-->
<!--                                <el-form-item label="" label-width="60px">-->
<!--                                    <el-button type="success" size="small" @click="autoMatchHandle">开始自动送审</el-button>-->
<!--                                </el-form-item>-->
<!--                            </el-form>    -->
<!--                        <el-button slot="reference" type="success" icon="el-icon-discover" style="margin:0 10px;"-->
<!--                            size="small">自动送审</el-button>-->
<!--                    </el-popover>-->

                    <!-- <el-button type="success" size="small" icon="el-icon-download" @click="downloadPaper">下载论文原件
                    </el-button> -->
                    <!-- <el-button type="success" size="small" @click="SpareMatchHandle" icon="el-icon-discover">备用专家匹配</el-button> -->

<!--                    <el-button type="success" size="small" icon="el-icon-position" @click="showMaildetails" style="margin:0 10px;">邮件详情</el-button>-->

                    <el-dialog title="发信详情" :visible.sync="maildetails">
                        <el-form  inline="true">
                            <el-form-item label="收信地址:">
                                <el-input placeholder="请输入收信地址" style="width:350px;" v-model="mailSearch.toAddress" size='medium'
                                    class="form-line-item">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="投递结果:">
                                <el-select v-model="mailSearch.status" size="medium" clearable placeholder="请选择投递结果"
                                    class="form-line-item"
                                        ref="selectStatus"
                                        @visible-change="isShowSelectOptions"
                                >
                                    <el-option label="成功" :value="0"></el-option>
                                    <el-option label="无效地址" :value="2"></el-option>
                                    <el-option label="垃圾邮件" :value="3"></el-option>
                                    <el-option label="失败" :value="4"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="" label-width="60px">
                                <el-button type="primary" size="mini" icon="el-icon-search" @click="getMailDetail">搜索</el-button>
                            </el-form-item>
                        </el-form>
                        
                        <el-table :data="maildetailslist" v-loading="mailLoading" element-loading-background = "rgba(0, 0, 0, 0.5)"
                        element-loading-text = "数据正在加载中" element-loading-spinner = "el-icon-loading">
                            <el-table-column property="lastUpdateTime" label="创建时间" width="150"></el-table-column>
                            <el-table-column property="toAddress" label="收信地址" width="250"></el-table-column>
                            <el-table-column property="status" label="投递状态" width="90">
                                <template #default="{row}">
                                    {{row.status == 0?"成功":row.status == 2?"无效地址":row.status == 3?"垃圾邮件":"失败"}}
                                </template>
                            </el-table-column>
                            <el-table-column property="message" label="详情" width="410"></el-table-column>
                        </el-table>
                    </el-dialog>
                </template>

                <template #tools>
<!--                    <el-popconfirm title="是否清除匹配？" @confirm='waitingMatch'>-->
<!--                        <el-button style="margin:0 10px;" slot="reference" type="warning" size="small" icon="el-icon-refresh-left" >清除匹配-->
<!--                        </el-button>-->
<!--                    </el-popconfirm>-->
<!--                    <el-popconfirm title="是否取消自动送审？" @confirm='calcelAutoSending'>-->
<!--&lt;!&ndash;                      <el-button type="warning" size="small" icon="el-icon-position" @click="calcelAutoSending">取消自动送审&ndash;&gt;-->
<!--&lt;!&ndash;                      </el-button>&ndash;&gt;-->
<!--                        <el-button style="margin:0 10px;" slot="reference" type="warning" size="small" icon="el-icon-position" >取消自动送审-->
<!--                      </el-button>-->
<!--                    </el-popconfirm>-->
                    <el-popconfirm title="是否发送催审信息？" @confirm='sendUrgeMail'>
  <!--                    <el-button type="warning" size="small" icon="el-icon-position" @click="sendUrgeMail">发送催审信息-->
  <!--                    </el-button>-->
                      <el-button style="margin:0 10px;" slot="reference" type="warning" size="small" icon="el-icon-position" >发送催审信息
                      </el-button>
                    </el-popconfirm>

                    <el-button type="success" size="small" icon="el-icon-edit" @click="editDeadline">批量修改送审规则
                    </el-button>

                    <el-popconfirm title="是否下载送审规则？" @confirm='downloadSendingRule'>
<!--                      <el-button type="success" size="small" icon="el-icon-download" @click="downloadSendingRule">下载送审规则-->
<!--                      </el-button>-->
                      <el-button style="margin:0 10px;" slot="reference" type="success" size="small" icon="el-icon-download" >下载送审规则
                      </el-button>
                    </el-popconfirm>

                    <el-button type="primary" size="small" style="margin-right:10px" icon="el-icon-upload"
                        @click="uploadSendingRule">上传送审规则
                    </el-button>

<!--                    <el-button type="primary" size="small" style="margin-right:10px" icon="el-icon-upload"-->
<!--                               @click="uploadFile">上传文件-->
<!--                    </el-button>-->


                    <!-- 其他操作弹窗 -->
                    <el-dialog title="操作面板" :visible.sync="otherActionsVisible" width="600px">
                      <div style="display: flex; flex-direction: row; gap: 10px; padding:10px; flex-wrap: wrap;">
<!--                        <el-popconfirm title="是否开始自动送审？" @confirm="autoMatchHandle">-->
<!--                          <template #reference>-->
<!--                          <el-button type="success" size="small" icon="el-icon-discover">自动送审</el-button>-->
<!--                          </template>-->
<!--                        </el-popconfirm>-->
                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 120px;">自动送审</el-col>
                          <el-col :span="16" style="display: flex; justify-content: center; gap: 10px;">
                        <el-popover ref="popover2" placement="right" trigger="click" >
                          <el-form label-position="left" >
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="评审意愿" label-width="70px">
                                <el-checkbox-group v-model="autoSending.accept" size="small">
                                  <el-checkbox-button label="A">A</el-checkbox-button>
                                  <el-checkbox-button label="B">B</el-checkbox-button>
                                  <el-checkbox-button label="C">C</el-checkbox-button>
                                </el-checkbox-group>
                              </el-form-item>
                              <!-- <el-form-item label="评审责任心" label-width="90px">
                                  <el-checkbox-group v-model="responsibility" size="small">
                                      <el-checkbox-button label="A">A</el-checkbox-button>
                                      <el-checkbox-button label="B">B</el-checkbox-button>
                                      <el-checkbox-button label="C">C</el-checkbox-button>
                                  </el-checkbox-group>
                              </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="评审速度" label-width="70px">
                                <el-checkbox-group v-model="autoSending.frequency" size="small">
                                  <el-checkbox-button label="A">A</el-checkbox-button>
                                  <el-checkbox-button label="B">B</el-checkbox-button>
                                  <el-checkbox-button label="C">C</el-checkbox-button>
                                </el-checkbox-group>
                              </el-form-item>
                              <!-- <el-form-item label="评审奇异性" label-width="90px">
                                  <el-checkbox-group v-model="singularity" size="small">
                                      <el-checkbox-button label="A">A</el-checkbox-button>
                                      <el-checkbox-button label="B">B</el-checkbox-button>
                                      <el-checkbox-button label="C">C</el-checkbox-button>
                                  </el-checkbox-group>
                              </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="评审质量" label-width="70px">
                                <el-checkbox-group v-model="autoSending.quality" size="small">
                                  <el-checkbox-button label="A">A</el-checkbox-button>
                                  <el-checkbox-button label="B">B</el-checkbox-button>
                                </el-checkbox-group>
                              </el-form-item>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="严格程度" label-width="70px">
                                <el-input placeholder="选填，请输入分数区间：80-90" style="width:230px;" v-model="autoSending.avgScore" size='medium'
                                          class="form-line-item">
                                </el-input>
                              </el-form-item>
                            </el-form>
                              <!-- <el-form-item label="评审均值" label-width="90px">
                                  <el-checkbox-group v-model="mean" size="small">
                                      <el-checkbox-button label="A">A</el-checkbox-button>
                                      <el-checkbox-button label="B">B</el-checkbox-button>
                                      <el-checkbox-button label="C">C</el-checkbox-button>
                                      <el-checkbox-button label="D">D</el-checkbox-button>
                                      <el-checkbox-button label="E">E</el-checkbox-button>
                                  </el-checkbox-group>
                              </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="专家库" label-width="70px">
                                <el-checkbox-group v-model="autoSending.checkedExperts" size="small" @change="autoSendingHandleExpertChange">
                                  <el-checkbox-button :label="0">活跃专家</el-checkbox-button>
                                  <el-checkbox-button :label="1">备用专家</el-checkbox-button>
                                </el-checkbox-group>
                              </el-form-item>
<!--                              <el-form-item label="启动备用专家"  label-width="100px">-->
<!--                                <el-radio-group v-model="autoSending.isSpare" size="small">-->
<!--                                  <el-radio-button :label="1">是</el-radio-button>-->
<!--                                  <el-radio-button :label="0">否</el-radio-button>-->
<!--                                </el-radio-group>-->
<!--                              </el-form-item>-->
                              <!-- <el-form-item label="评审标准差" label-width="90px">
                                  <el-checkbox-group v-model="standard_deviation" size="small">
                                      <el-checkbox-button label="A">A</el-checkbox-button>
                                      <el-checkbox-button label="B">B</el-checkbox-button>
                                      <el-checkbox-button label="C">C</el-checkbox-button>
                                      <el-checkbox-button label="D">D</el-checkbox-button>
                                      <el-checkbox-button label="E">E</el-checkbox-button>
                                  </el-checkbox-group>
                              </el-form-item> -->
                            </el-form>
                            <el-form :inline="true" class="demo-form-inline">
                              <el-form-item label="匹配学科"  label-width="90px">
                                <el-radio-group v-model="autoSending.isSecondMajor" size="small">
                                  <el-radio-button :label="0">一级学科</el-radio-button>
                                  <el-radio-button :label="1">二级学科</el-radio-button>
                                </el-radio-group>
                              </el-form-item>
                            </el-form>
                            <el-form-item label="天数"  label-width="55px">
                              <el-input-number v-model="autoSending.days" size="small" :min="1" label="请输入天数"></el-input-number>
                            </el-form-item>
                            <el-form-item label="" label-width="60px">
                              <el-button type="success" size="small" @click="autoMatchHandle">开始自动送审</el-button>
                            </el-form-item>
                          </el-form>
                          <el-button slot="reference" type="primary" icon="el-icon-position"
                                     size="small">启动自动送审</el-button>
                        </el-popover>


                        <el-popconfirm title="是否取消自动送审？" @confirm="calcelAutoSending">
                          <template #reference>
                          <el-button type="primary" size="small" icon="el-icon-discover">取消自动送审</el-button>
                          </template>
                        </el-popconfirm>
                          </el-col>
                        </el-row>


                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 120px;">邮件查看</el-col>
                          <el-col :span="16">
                        <el-button type="primary" size="small" icon="el-icon-position" @click="showMaildetails">查看邮件详情</el-button>
                          </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 120px;">文件替换</el-col>
                          <el-col :span="16">
                        <el-button type="primary" size="small" icon="el-icon-upload" @click="uploadFile">上传替换文件</el-button>
                          </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 92px;">批量新增专家</el-col>
                          <el-col :span="16">
                        <el-button type="primary" size="small" style="margin-right:10px" icon="el-icon-upload"
                                   @click="uploadSendingResult">批量新增专家
                        </el-button>
                        </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 77px;">导出送审过程表</el-col>
                          <el-col :span="16">
                            <el-popconfirm title="是否导出送审过程表？" @confirm='exportSendingDetail'>
                              <el-button slot="reference" type="primary" size="small" icon="el-icon-download" >导出送审过程表
                              </el-button>
                            </el-popconfirm>
                          </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 90px;">多个送审规则</el-col>
                          <el-col :span="16" style="display: flex; justify-content: center; gap: 10px;">
                            <el-popconfirm title="是否下载送审规则？" @confirm='downloadSendingRuleSpecial'>
                              <!--                      <el-button type="success" size="small" icon="el-icon-download" @click="downloadSendingRule">下载送审规则-->
                              <!--                      </el-button>-->
                              <el-button style="margin:0 0px;" slot="reference" type="primary" size="small" icon="el-icon-download" >下载送审规则
                              </el-button>
                            </el-popconfirm>

                            <el-button type="primary" size="small" style="margin-right:10px" icon="el-icon-upload"
                                       @click="uploadSendingRuleSpecial">上传送审规则
                            </el-button>
                          </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 120px;">清除匹配</el-col>
                          <el-col :span="16">
                        <el-popconfirm title="是否清除匹配？" @confirm="waitingMatch">
                          <template #reference>
                          <el-button type="primary" size="small" icon="el-icon-refresh-left">清除现有匹配</el-button>
                          </template>
                        </el-popconfirm>
                          </el-col>
                        </el-row>

                        <el-divider></el-divider>

                        <el-row type="flex" justify="center" align="middle">
                          <el-col :span="16" style="text-align: left; margin-right: 92px;">上传催审记录</el-col>
                          <el-col :span="16">
                            <el-button type="primary" size="small" style="margin-right:10px" icon="el-icon-upload"
                                       @click="uploadSendingDetail">上传催审记录
                            </el-button>
                          </el-col>
                        </el-row>

                      </div>
                      <div slot="footer" class="dialog-footer">
                        <el-button @click="otherActionsVisible = false">关闭</el-button>
                      </div>
                    </el-dialog>

                  <!-- 其他操作按钮 -->
                  <el-button type="primary" size="small" icon="el-icon-more" @click="openOtherActions">其他操作</el-button>


                </template>




            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar paperPageList" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" show-footer
                :footer-method="footerMethod" :merge-footer-items="mergeFooterItems" :loading='loading'
                :data="paperList"

            >
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="title" title="题目" min-width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="oldId" title="编号" width="120" :show-overflow="'tooltip'"></vxe-column>
<!--                <vxe-column field="student" title="姓名" width="120" :show-overflow="'tooltip'"></vxe-column>-->
<!--                <vxe-column field="studentNum" title="学号" width="120" :show-overflow="'tooltip'"></vxe-column>-->
<!--                <vxe-column field="supervisor" title="导师" width="120" :show-overflow="'tooltip'"></vxe-column>-->
                <vxe-column field="institutionName" title="单位" width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchName" title="批次" width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperType" title="学位类型" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="subjectMajor" title="学科" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="pendingNum" title="待匹配" width="85" sortable>
                    <template #default="{ row }">
                        {{row.pendingNum}}/{{row.expertNumber}}
                    </template>
                </vxe-column>
                <vxe-column field="waitingNum" title="待送审" width="85" sortable>
                    <template #default="{ row }">
                        {{row.waitingNum}}/{{row.expertNumber}}
                    </template>
                </vxe-column>
                <vxe-column field="sendingNum" title="送审中" width="85" sortable>
                    <template #default="{ row }">
                        {{row.sendingNum}}/{{row.expertNumber}}
                    </template>
                </vxe-column>
                <vxe-column field="reviewNum" title="评审中" width="85" sortable>
                    <template #default="{ row }">
                        {{row.reviewNum}}/{{row.expertNumber}}
                    </template>
                </vxe-column>
                <vxe-column field="finishedNum" title="已完成" width="85" sortable>
                    <template #default="{ row }">
                        {{row.finishedNum}}/{{row.expertNumber}}
                    </template>
                </vxe-column>
                <vxe-column field="endTime" title="截止时间" width="100" :show-overflow="'tooltip'" sortable>
                    <!-- <template #default="{row}">
                        <div :style="{color:autoPaperIds.indexOf(row.paperId) == -1?'':'red'}">{{row.endTime }}</div>
                    </template> -->
                </vxe-column>
                <vxe-column field="returnTime" title="交付时间" width="110" :show-overflow="'tooltip'" sortable>
                  <!-- <template #default="{row}">
                      <div :style="{color:autoPaperIds.indexOf(row.paperId) == -1?'':'red'}">{{row.endTime }}</div>
                  </template> -->
                </vxe-column>
                <vxe-column title="操作" field="note" width="150" fixed="right">
                    <template #default="{ row }">
                        <el-button size="mini" type="text" @click="handleSingleCondition(row.paperId,row.batchId,row.title)">
                            详情
                        </el-button>
                        <el-button size="mini" type="text" @click="editSendingRuleHandle(row.paperId)">规则
                        </el-button>
                        <el-button size="mini" type="text" :style="{'color':row.note?'red':'#1890ff'}" @click="editNoteHandle(row)">备注
                        </el-button>

                        <el-popover
                            placement="right"
                            width="400"
                            trigger="click">
                            <el-descriptions :labelStyle="{ 'width': '40%' }" :column="1" border>
                                <el-descriptions-item label="开始时间">{{autoSendingDetail.startTime}}</el-descriptions-item>
                                <el-descriptions-item label="截至时间">{{autoSendingDetail.endTime}}</el-descriptions-item>
                                <el-descriptions-item label="自动送审天数">{{autoSendingDetail.days}}</el-descriptions-item>
                                <el-descriptions-item label="自动送审天数">{{autoSendingDetail.days}}</el-descriptions-item>
                            </el-descriptions>
                            <el-button style="margin-left:5px;" slot="reference" size="mini" :class="row.matchType == 3?'el-icon-user-solid':row.matchType == 1?'el-icon-star-on':'el-icon-star-off'" @click="autoSendingHandle(row)" type="text"></el-button>
                        </el-popover>

                        <!-- <i :class="row.matchType == 3?'el-icon-user-solid':row.matchType == 1?'el-icon-star-on':'el-icon-star-off'" @click="autoSendingHandle(row)" style="padding-left: 5px;color: #1890ff;"></i> -->
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 弹窗 -->
<!--        <vxe-modal v-model="editDeadlineModal" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize-->
<!--            :transfer='true'>-->
<!--            <template #title>-->
<!--                批量修改送审规则-->
<!--            </template>-->
<!--            <vxe-form :data="editPaper" title-align="right" title-width="150">-->
<!--                <vxe-form-item title="截止日期：" :span="20" :item-render="{}">-->
<!--                    <vxe-input v-model="editPaper.endData" placeholder="日期选择" type="date" transfer></vxe-input>-->
<!--                </vxe-form-item>-->
<!--            </vxe-form>-->
<!--            <vxe-form :data="editPaper" title-align="right" title-width="150">-->
<!--                <vxe-form-item title="送审数量：" :span="20" :item-render="{}">-->
<!--                    <vxe-input v-model="editPaper.sendingNumber" placeholder="单篇论文送审数量" type="number" transfer>-->
<!--                    </vxe-input>-->
<!--                </vxe-form-item>-->
<!--            </vxe-form>-->
<!--            <vxe-form :data="editPaper" title-align="right" title-width="150">-->
<!--                <vxe-form-item title="报酬金额：" :span="20" :item-render="{}">-->
<!--                  <vxe-input v-model="editPaper.reward" placeholder="单篇论文送审报酬金额" type="float" :controls="false" transfer></vxe-input>-->
<!--                </vxe-form-item>-->
<!--            </vxe-form>-->
<!--            <vxe-form :data="editPaper" title-align="right" title-width="150">-->
<!--                <vxe-form-item title="映射学硕一级学科：" :span="20" :item-render="{}">-->
<!--                    <vxe-input v-model="editPaper.mappingMajor" placeholder="修改映射学硕一级学科" type="text" transfer></vxe-input>-->
<!--                </vxe-form-item>-->
<!--            </vxe-form>-->
<!--            <vxe-form :data="editPaper" title-align="right" title-width="150">-->
<!--                <vxe-form-item title="映射二级学科：" :span="20" :item-render="{}">-->
<!--                    <vxe-input v-model="editPaper.mappingSecondMajor" placeholder="修改映射二级学科" type="text" transfer></vxe-input>-->
<!--                </vxe-form-item>-->
<!--            </vxe-form>-->
<!--            <template #footer>-->
<!--                <el-button type="primary" size="small" @click='cfmEdit'>确认</el-button>-->
<!--            </template>-->
<!--        </vxe-modal>-->
        <vxe-modal v-model="editDeadlineModal" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
                   :transfer='true'>
          <template #title>
            批量修改送审规则
          </template>
          <vxe-form :data="editPaper" title-align="right" title-width="150">
            <vxe-form-item title="截止日期：" :span="20" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.endData" placeholder="日期选择" type="date" transfer></vxe-input>
              </template>
            </vxe-form-item>

            <vxe-form-item title="送审数量：" :span="20" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.sendingNumber" placeholder="单篇论文送审数量" type="number" transfer></vxe-input>
              </template>
            </vxe-form-item>

            <vxe-form-item title="报酬金额：" :span="20" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.reward" placeholder="单篇论文送审报酬金额" type="float" :controls="false" transfer></vxe-input>
              </template>
            </vxe-form-item>

            <vxe-form-item title="映射学硕一级学科：" :span="20" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.mappingMajor" placeholder="修改映射学硕一级学科" type="text" transfer></vxe-input>
              </template>
            </vxe-form-item>

            <vxe-form-item title="映射二级学科：" :span="20" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.mappingSecondMajor" placeholder="修改映射二级学科" type="text" transfer></vxe-input>
              </template>
            </vxe-form-item>
          </vxe-form>
          <template #footer>
            <el-button type="primary" size="small" @click='cfmEdit'>确认</el-button>
          </template>
        </vxe-modal>

        <vxe-modal v-model="editNoteModal" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                添加备注
            </template>
            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 20}" placeholder="请输入内容"
                v-model="paperInfo.note">
            </el-input>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmEditNote'>确认</el-button>
            </template>
        </vxe-modal>

        <!-- 弹窗 -->
        <vxe-modal v-model="editRuleModal" class="modal-scrollable" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                修改单篇送审规则
            </template>
            <vxe-form v-for="(rule, index) in sendingRules" :key="index" :data="rule" title-align="right" title-width="110">
              <vxe-form-item field="paperFilterId" title="送审规则ID：" :span="20" :item-render="{}">
                <template #default="{ data }">
                  {{ data.paperFilterId }}
                </template>
              </vxe-form-item>

              <vxe-form-item field="universityLevel" title="单位类型" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.universityLevel" placeholder="请选择" size="medium" multiple clearable>
                    <vxe-option v-for="item in options.universityLevel" :key="item" :value="item" :label="item">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="universityRanking" title="院校排名" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.universityRanking[0]" placeholder="开始" type="number"
                             className="number"></vxe-input> -
                  <vxe-input v-model="data.universityRanking[1]" placeholder="结束" type="number"
                             className="number"></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertType" title="导师类型" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.expertType" placeholder="请选择" size="medium" multiple clearable>
                    <vxe-option v-for="item in options.supervisorType" :key="item.supervisorTypeId"
                                :value="String(item.supervisorTypeId)" :label="item.type">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertTitle" title="专家职称" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.expertTitle" placeholder="请选择" size="medium" multiple clearable>
                    <vxe-option v-for="item in options.expertTitle" :key="item.expertTitleId"
                                :value="String(item.expertTitleId)" :label="item.title">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>

              <vxe-form-item field="sendingNum" title="送审意见数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.sendingNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="localSendingNum" title="本校意见数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.localSendingNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="expertReviewNum" title="专家最大接审数" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.expertReviewNum" placeholder="整数类型" type="integer">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="mappingMajor" title="映射一级学科" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.mappingMajor" placeholder="映射一级学科" type="text">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="majorGrade" title="匹配等级" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.majorGrade" placeholder="匹配等级" clearable multiple transfer>
                    <vxe-option v-for="item in levels" :key="item" :value="item" :label="item">
                    </vxe-option>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="mappingSecondMajor" title="映射二级学科" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.mappingSecondMajor" placeholder="映射二级学科" type="text">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarbExperts" title="回避指定专家" :span="12" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.debarbExperts" placeholder="专家id请用中文顿号‘、’分隔" type="text">
                  </vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarb" title="是否回避" :span="24" :item-render="{}">
                <template #default="{ data }">
                  <vxe-radio-group v-model="data.debarb">
                    <vxe-radio :label="1" content="是"></vxe-radio>
                    <vxe-radio :label="0" content="否"></vxe-radio>
                  </vxe-radio-group>
                </template>
              </vxe-form-item>
              <vxe-form-item field="debarbUniversity" title="回避院校" :span="18" v-if="rule.debarb == 1"
                             key="debarbUniversity" :item-render="{}">
                <template #default="{ data }">
                  <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}" key="debarbUniversity_key"
                            placeholder="院校请用中文顿号‘、’分隔" v-model="data.debarbUniversity">
                  </el-input>
                </template>
              </vxe-form-item>
              <!-- <vxe-form-item :span="6" key="debarbUniversity0" v-if="sendingRule.debarb == 1" :item-render="{}">
                  <template #default="{ data }">
                      <el-upload class="upload-demo" action='#' :auto-upload='false' key="debarbUniversity0_key"
                          :show-file-list='false' accept=".xls,.xlsx" :on-change='uploadDebarb' :limit="1"
                          style="float:left">
                          <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                          </el-button>
                      </el-upload>
                      <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                      </el-link>
                  </template>
              </vxe-form-item> -->
              <vxe-form-item field="debarbRegion" title="回避省份" :span="18" v-if="rule.debarb == 1"
                             key="debarbRegion" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.debarbRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                    <vxe-optgroup v-for="province in locations" :label="province.location" >
                      <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                      <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                    </vxe-optgroup>
                  </vxe-select>
                </template>
              </vxe-form-item>
              <vxe-form-item field="appoint" title="是否指定" :span="24" :item-render="{}">
                <template #default="{ data }">
                  <vxe-radio-group v-model="data.appoint">
                    <vxe-radio :label="1" content="是"></vxe-radio>
                    <vxe-radio :label="0" content="否"></vxe-radio>
                  </vxe-radio-group>
                </template>
              </vxe-form-item>
              <vxe-form-item field="appointUniversity" title="指定院校" key="appointUniversity" :span="18"
                             v-if="rule.appoint == 1" :item-render="{}">
                <template #default="{ data }">
                  <el-input type="textarea" :autosize="{ minRows: 1,maxRows:4}" placeholder="院校请用中文顿号‘、’分隔"
                            v-model="data.appointUniversity">
                  </el-input>
                </template>
              </vxe-form-item>

              <!-- <vxe-form-item field="appointUniversity" :span="6" v-if="sendingRule.appoint == 1"
                  key="appointUniversity0" :item-render="{}">
                  <template #default="{ data }">
                      <el-upload class="upload-demo" action='#' :auto-upload='false' :show-file-list='false'
                          accept=".xls,.xlsx" :on-change='uploadAppoint' :limit="1" style="float:left">
                          <el-button size="mini" type="primary" style="margin-right:10px">上传excel
                          </el-button>
                      </el-upload>
                      <el-link type="info" href='/static/指定回避院校模板.xlsx' class="mylink">院校模板下载
                      </el-link>
                  </template>
              </vxe-form-item> -->
              <vxe-form-item field="appointRegion" key="appointRegion" title="指定省份" :span="18"
                             v-if="rule.appoint == 1" :item-render="{}">
                <template #default="{ data }">
                  <vxe-select v-model="data.appointRegion" placeholder="请选择地区" filterable multiple clearable transfer>
                    <vxe-optgroup v-for="province in locations" :label="province.location">
                      <vxe-option v-if="province.markets.length > 1" :value="province.location" :label="province.location"></vxe-option>
                      <vxe-option :value="market" :label="market" v-for="market in province.markets"></vxe-option>
                    </vxe-optgroup>
                  </vxe-select>
                </template>
              </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmEditSingleRule'>确认</el-button>
            </template>
        </vxe-modal>
        <!-- 上传送审文件 -->
        <vxe-modal v-model="uploadFileModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
                   resize :transfer="true" :z-index="3000">
            <template #title>
                上传文件
            </template>
          <div style="display: flex; justify-content: center; align-items: center;">
            <el-form :inline="true" class="demo-form-inline"  >
                <el-form-item label="单位:">
                    <el-select v-if="institutionList.length" v-model="fileAddInfo.institutionId" size="medium" clearable filterable
                               @change="handleFileAddInstitution" placeholder="请选择单位" class="form-line-item"
                               ref="selectFileInstitution" :popper-append-to-body="false">
                        <el-option v-for="(item,index) in institutionList" :key='index'
                                   :label="item.institutionName" :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="fileAddInfo.batchId" size='medium' clearable placeholder="请选择批次"
                               class="form-line-item" :disabled='fileAddInfo.batchShow'
                               ref="selectFileBatchId" :popper-append-to-body="false"

                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                   :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
          </div>


          <div style="display: flex; justify-content: center; align-items: center;">
            <el-upload class="upload-demo" ref='paperFileInfo' :auto-upload='false' drag action="#">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将送审文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip" style="text-align: center;">请上传zip文件。</div>
            </el-upload>
          </div>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadFile'>确认</el-button>
            </template>
        </vxe-modal>


        <!-- 上传送审规则 -->
        <vxe-modal v-model="uploadSendingRuleModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
            resize :transfer='true'>
            <template #title>
                上传送审规则
            </template>
            <el-upload class="upload-demo" ref='paperInfo' :auto-upload='false' drag action="#">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将送审规则信息表拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
            </el-upload>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadSendingRule'>确认</el-button>
            </template>
        </vxe-modal>
        <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
            :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>

        <vxe-modal v-model="uploadSendingRuleSpecialModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
                   resize :transfer='true' :z-index="3000">
          <template #title>
            上传送审规则
          </template>
          <el-upload class="upload-demo" ref='paperInfo' :auto-upload='false' drag action="#">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将送审规则信息表拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
          </el-upload>
          <template #footer>
            <el-button type="primary" size="small" @click='cfmUploadSendingRuleSpecial'>确认</el-button>
          </template>
        </vxe-modal>
        <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
                   :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
          <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
          <!-- <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span> -->
        </el-dialog>

        <!-- 上传送审记录 -->
        <vxe-modal v-model="uploadSendingResultModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
                   resize :transfer='true' :z-index="3000">
          <template #title>
            批量新增专家
          </template>
          <el-upload class="upload-demo" ref='resultInfo' :auto-upload='false' drag action="#">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将送审记录信息表拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
          </el-upload>
          <template #footer>
            <el-button type="primary" size="small" @click='cfmUploadSendingResult'>确认</el-button>
          </template>
        </vxe-modal>
        <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
                   :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
          <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
          <!-- <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span> -->
        </el-dialog>

      <!-- 上传催审记录 -->
      <vxe-modal v-model="uploadSendingDetailModel" :position="{top: '0px'}" width="600" :show-footer='true' show-zoom
                 resize :transfer='true' :z-index="3000">
        <template #title>
          上传送审记录
        </template>
        <el-upload class="upload-demo" ref='resultInfo' :auto-upload='false' drag action="#">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将送审记录信息表拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">请上传xls/xlsx文件。</div>
        </el-upload>
        <template #footer>
          <el-button type="primary" size="small" @click='cfmUploadSendingDetail'>确认</el-button>
        </template>
      </vxe-modal>
      <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
                 :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
        <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
      </el-dialog>

    </div>
</template>

<script>
    import axios from 'axios';
    import XLSX from 'xlsx';
    import { Loading } from 'element-ui';
    
    // import dateFormat from '../../../public/tools/date'
    export default {
        name: 'PaperPage',
        data() {
            return {
                // institutionName: this.$route.query.institutionName || '',
                // batchName: this.$route.query.batchName || '',
                otherActionsVisible: false, // 控制弹窗显示
                editDeadlineModal: false,
                editRuleModal: false,
                editNoteModal: false,
                uploadSendingRuleModel: false,
                uploadSendingRuleSpecialModel: false,
                uploadSendingResultModel: false,
                uploadSendingDetailModel: false,
                uploadFileModel:false,
                maildetails:false,
                dialogTableVisible: false,
                mailLoading: false,
                //数据加载
                loading: false,
                select: '1',
                //搜索条件
                institutionList: [],
                batchList: [],
                search: {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    studentNum: '',
                    supervisor: '',
                    paperType: '',
                    // author: '',
                    state: '',
                    major: '',
                    haveNote:'',
                    sendingType:'',
                },
                mailSearch: {},
                batchShow: true,
                //论文列表
                paperList: [
                    //     {
                    //     paperId: 1,
                    //     title: '论文题目',
                    //     institutionName: '南京理工大学',
                    //     batchName: '2021硕士第一批',
                    //     // statue: '送审中',
                    //     endTime: '2022-10-10',
                    //     waitingNum: 0,
                    //     totalReviewNum: 0,
                    //     finishedNum: 0,
                    //     reviewNum: 0
                    // }
                ],
                maildetailslist: [

                ],
                mergeFooterItems: [{
                    row: 0,
                    col: 0,
                    rowspan: 0,
                    colspan: 6
                }],
                //分页
                page: {
                    pageIndex: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [10, 20, 50, 100, 200, 500, {
                        label: '全部数据',
                        value: 10000
                    }],
                    total: 0
                },
                //评阅书列表
                receiptList: [{
                    expertName: 'sh',
                    university: '正高',
                    state: '0',
                    resultKeywords: '',
                    paperKeywords: ''
                }],
                paperKeywords: '',
                //发送进度条
                sendProcessVis: false,
                showClose: false,
                sendProcessTitle: '发送中',
                process: 0,
                sendTimer: '',
                expertLevel: [],
                selectLoading: false,
                editPaper: {
                    endData: '',
                    sendingNumber: '',
                    mappingMajor: '',
                    mappingSecondMajor:'',
                    reward:''
                },
                cancelToken: {
                    getP2pPaperSource: null
                    // getMailDetailSource:null
                },
                levels: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-','其他'],
                // 单篇送审规则
                // sendingRule: {
                //     "universityLevel": [],
                //     "supervisorType": [],
                //     "expertTitle": [],
                //     "debarb": "0",
                //     "appoint": "0",
                //     "sendingNum": "2",
                //     "localSendingNum": "0",
                //     "expertReviewNum": "2",
                //     "debarbUniversity": "",
                //     "appointUniversity": "",
                //     "appointRegion": '',
                //     "debarbRegion": '',
                //     "debarbExperts": '',
                //     "universityRanking": ['', ''],
                // },
                sendingRules:[],
                options: [],
                // 论文信息
                paperInfo: {
                    paperId:'',
                    note: ''
                },
                quality: ['A','B'],
                accept: ['A','B','C'],
                frequency: ['A','B','C'],
                avgScore:'',
                // responsibility: ['A','B','C'],
                // singularity: ['A','B','C'],
                // mean:['A','B','C','D','E'],
                // standard_deviation:['A','B','C','D','E'],
                checkedExperts:[0],
                isSpare:0,
                isSecondMajor:0,
                // basicScore: '',
                // 需要修改的备注的id，
                locations:'',
                // 选中带论文
                // selectNotes:[],
                // selectNotesModal:false，
                autoSending:{
                    quality: ['A','B'],
                    accept: ['A','B','C'],
                    frequency: ['A','B','C'],
                    checkedExperts:[0],
                    // responsibility: ['A','B','C'],
                    // singularity: ['A','B','C'],
                    // mean:['A','B','C','D','E'],
                    // standard_deviation:['A','B','C','D','E'],
                    isSpare:0,
                    isSecondMajor:0,
                    days:0
                },
                paperLength: 0,
                count: 0,
                paperPercent: 0,
                matchText:'',
                sendText:'',
                matchLoading:'',
                sendLoading:'',
                autoPaperIds:[],
                autoSendingDetail:{},
                ws:null,
                matchRefresh:null,
                sendRefresh:null,
                fileAddRules:{
                    batchId: [
                        { required: true, message: '请输入批次' }
                    ],

                },
                fileAddInfo:{
                    batchShow:true,
                    institutionId:'',
                    batchId:""
                }
            }
        },
        mounted() {
          this.getInstitution();
        },
        activated() {
            // this.resetHandle();

            this.initSearchParams();
            this.handleInstitutionChange();

            // this.getP2pPaper();
            // websocket建立
            this.openWebSocket();

            console.log("paperlist",this.paperList)
        },
        // activated() {
        //     this.$nextTick(() => {
        //         console.log(this.$store.state.sendRecord.scrollTop);
        //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
        //     })
        // },
        created() {
            
            window.addEventListener('keydown', this.handleKeyPress);
            this.initSearchParams();
            this.getFilterOptions();
            this.getLocation();

        },
        // 监听路由参数变化
        // watch: {
        //   '$route.query': {
        //     handler(newQuery) {
        //       this.initSearchParams();
        //     },
        //     immediate: true,
        //     deep: true
        //   }
        // },
        destroyed(){
          // clearInterval(this.timer);
          this.ws.close()
        },
        deactivated(){
          this.ws.close();
          this.paperList=[];
        },
        methods: {
          openOtherActions() {
            this.otherActionsVisible = true; // 打开弹窗
          },
          handleKeyPress(event) {
            if (event.keyCode === 13 && !this.editNoteModal) {
              // console.log(123)
              this.batchSearch()
            }
          },
          handleExpertChange(value) {
            if (value.includes(0) && value.includes(1)) {
              this.isSpare = 2;
            } else if (value.includes(0)) {
              this.isSpare = 0;
            } else if (value.includes(1)) {
              this.isSpare = 1;
            } else {
              this.isSpare = null;
            }
          },
          autoSendingHandleExpertChange(value) {
            if (value.includes(0) && value.includes(1)) {
              this.autoSending.isSpare = 2;
            } else if (value.includes(0)) {
              this.autoSending.isSpare = 0;
            } else if (value.includes(1)) {
              this.autoSending.isSpare = 1;
            } else {
              this.autoSending.isSpare = null;
            }
          },
          // 获取选项列表
          getFilterOptions() {
            this.$api.schoolCustom.getFilterOptions().then(res => {
              if (res.data.code = 200) {
                this.options = res.data.data;
              }
            }).catch(err => {
              this.$message.warning("请求出错");
            })
          },
          getLocation() {
            this.$api.schoolCustom.getLocation()
                .then(res => {
                  if (res.data.code == 200) {
                    this.locations = res.data.data;
                  }
                })
          },

          // 初始化搜索参数并自动触发搜索
          initSearchParams() {
            const { institutionId, batchId } = this.$route.query;

            // 重新赋值 search 参数
            if (institutionId) {
              this.search.institutionId = parseInt(institutionId) ;
            }

            if (batchId) {
              this.search.batchId = parseInt(batchId) ;
            }

            // 自动触发搜索
            this.getP2pPaper();
          },
          // 获取论文列表
          getP2pPaper() {
            console.log("请求参数",this.search);
            console.log('取消', this.cancelToken.getP2pPaperSource);
            if (this.cancelToken.getP2pPaperSource) {
              this.cancelToken.getP2pPaperSource.cancel();
              this.cancelToken.getP2pPaperSource = null;
            }
            this.loading = true;
            let param = new URLSearchParams();
            param.append("institutionId", this.search.institutionId);
            param.append("batchId", this.search.batchId);
            param.append("state", this.search.state);
            param.append("title", this.search.title);
            param.append("oldId", this.search.oldId);
            param.append("student", this.search.student);
            param.append("studentNum", this.search.studentNum);
            param.append("supervisor", this.search.supervisor);
            param.append("major", this.search.major);
            param.append("haveNote", this.search.haveNote);
            param.append('matchType', this.search.sendingType);
            param.append("paperTye", this.search.paperType);
            param.append("pageIndex", this.page.pageIndex);
            param.append("pageSize", this.page.pageSize);
            param.append("isFiled", 0);
            const cancelToken = axios.CancelToken;
            this.cancelToken.getP2pPaperSource = cancelToken.source();
            console.log('赋值', this.cancelToken.getP2pPaperSource);
            console.log('param:', param.toString())
            this.$api.sendingP2p.getP2pPaper(param)
                .then(res => {
                  console.log(res);
                  this.cancelToken.getP2pPaperSource = null;
                  if (res.data.code == 200) {
                    console.log(res.data);
                    this.autoPaperIds = res.data.data.autoPaperIds;
                    this.paperList = res.data.data.data;
                    this.page.total = res.data.count;
                    this.loading = false;
                  }
                }, err => {
                  // this.cancelToken.getP2pPaperSource = null;
                  // this.$message.warning("服务器维护111");
                })
          },


            showMaildetails(){
                this.maildetails = true;
                this.mailSearch = {};
                this.getMailDetail();
            },
            //获取邮件发送详情
            getMailDetail(){
                // this.loading = true;
                // let param = new URLSearchParams();
                // param.append("toaddress", this.search.expertName);
                // param.append("status", this.search.status);
                let params = {
                    "toAddress": this.mailSearch.toAddress,
                    "status": this.mailSearch.status
                }
                console.log(params);
                this.mailLoading = true;
                this.$api.sendingP2p.getMailDetail(params)
                    .then(res => {
                        console.log(111111111);
                        console.log(res);
                        if (res.data.code == 200) {
                            this.$message.success("查询成功")
                            this.maildetailslist = res.data.data;
                            this.mailLoading = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                        console.log(err);
                        this.mailLoading = false;
                    })
            },
            //将待送审论文恢复为待匹配
            waitingMatch(){
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length == 0) {
                    this.$message.warning("请选择论文");
                    return;
                }
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                })
                console.log(paperIds);
                this.$api.sendingP2p.recoverWaitingMatch(paperIds)
                        .then(res => {
                            // loading.close();
                            if (res.data.code == 200) {
                                this.$message.success("恢复成功！");
                                this.getP2pPaper();
                            } else {
                                this.$message.warning("恢复失败!");
                            }
                        }).catch(err => {
                            // loading.close();
                            this.$message.warning("服务器维护！");
                            console.log(err)
                        })
            },
            //表格刷新
            refresh() {
                this.getP2pPaper();
            },
            match(params){
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                this.paperLength = selectRecords.length;
                if (selectRecords.length == 0) {
                    this.$message.warning("请选择论文");
                    return;
                }
                let idInfos = [];
                let notes = [];
                selectRecords.forEach((item) => {
                    idInfos.push({
                        paperId: item.paperId
                    });
                    if(item.note){
                        notes.push(item);
                    }
                })
                params["idInfos"] = idInfos;
                const startMatch = (params)=>{
                    // this.matchText = "正在匹配中 "+ this.count + "/" + this.paperLength
                    this.matchLoading = Loading.service({fullscreen: true, text: '正在匹配'})
                    // this.matchLoading.setText(this.matchText)
                    // this.matchRefresh = setInterval(this.getMatchCount, 1000);
                    this.$api.sendingP2p.getMatch(params)
                        .then(res => {
                            this.matchLoading.close();
                            this.count = 0;
                            if (res.data.code == 200) {
                                this.$message.success("匹配成功！");
                                // clearInterval(this.matchRefresh);
                                this.getP2pPaper();
                            } else {
                                this.$message.warning("匹配失败!");
                                // clearInterval(this.matchRefresh);
                            }
                        }).catch(err => {
                            this.matchLoading.close();
                            this.$message.warning("服务器维护！");
                            // clearInterval(this.matchRefresh);

                            console.log(err);
                        })
                }
                if(notes.length > 0){
                    this.$confirm('选中包含带备注论文，是否继续匹配?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                            startMatch(params);
                        }).catch(() => {
                            this.$message({
                                type: 'success',
                                message: '已取消'
                            });          
                        });
                }else{
                    startMatch(params);
                }
            },
            matchHandle() {
                let params = {
                    matchType: 'out',
                    accept: this.accept.join(','),
                    frequency: this.frequency.join(','),
                    quality: this.quality.join(','),
                    // responsibility: this.responsibility.join(','),
                    // singularity: this.singularity.join(','),
                    // mean: this.mean.join(','),
                    // standard_deviation: this.standard_deviation.join(','),
                    isSpare:this.isSpare,
                    isSecondMajor:this.isSecondMajor,
                    isUsed:0,
                    resultIds: null,
                    avgScore:this.avgScore,
                    // isDetailMatch: isDetailMatch,
                };
                // 检查每个条件是否至少选择了一项
                if (!params.accept || !params.frequency || !params.quality) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }

                // 检查 isSpare 和 isSecondMajor 是否被定义（不为 null 或 undefined）
                if (params.isSpare === null || params.isSpare === undefined ||
                    params.isSecondMajor === null || params.isSecondMajor === undefined) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }
                this.match(params);

            },
            confirmFineMatch() {
              this.matchHandle(1); // 精细匹配时传入参数 1
            },
            confirmQuickMatch() {
              this.matchHandle(0); // 快速匹配时传入参数 0
            },
            autoMatch(params){
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length == 0) {
                    this.$message.warning("请选择论文");
                    return;
                }
                let paperIds = [];
                let notes = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                    if(item.note){
                        notes.push(item);
                    }
                })
                params["paperIds"] = paperIds;
                const startMatch = (params)=>{
                    const loading = this.$loading({
                        lock: true,
                        text: '正在开始自动送审',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$api.sendingP2p.postAutoSending(params)
                        .then(res => {
                            loading.close();
                            if (res.data.code == 200) {
                                this.$message.success("开始自动送审成功！");
                                this.getP2pPaper();
                            } else {
                                this.$message.warning("开始自动送审失败!");
                            }
                            this.$refs.popover2.doClose();
                        }).catch(err => {
                            loading.close();
                            this.$message.warning("服务器维护！");
                            console.log(err)
                        })
                }
                if(notes.length > 0){
                    this.$confirm('选中包含带备注论文，是否继续匹配?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                            startMatch(params);
                        }).catch(() => {
                            this.$message({
                                type: 'success',
                                message: '已取消'
                            });          
                        });
                }else{
                    startMatch(params);
                }
            },
            autoMatchHandle(){
                let params = {
                    accept: this.autoSending.accept.join(','),
                    frequency: this.autoSending.frequency.join(','),
                    quality: this.autoSending.quality.join(','),
                    avgScore: this.autoSending.avgScore,
                    // responsibility: this.autoSending.responsibility.join(','),
                    // singularity: this.autoSending.singularity.join(','),
                    // tightness: this.autoSending.tightness.join(','),
                    isSpare:this.autoSending.isSpare,
                    isSecondMajor:this.autoSending.isSecondMajor,
                    isUsed:0,
                    days:this.autoSending.days
                }
                this.autoMatch(params);
                // 检查每个条件是否至少选择了一项
                if (!params.accept || !params.frequency || !params.quality) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }

                // 检查 isSpare 和 isSecondMajor 是否被定义（不为 null 或 undefined）
                if (params.isSpare === null || params.isSpare === undefined ||
                    params.isSecondMajor === null || params.isSecondMajor === undefined) {
                  this.$message.warning("每个条件至少选择一项");
                  return;
                }
            },
            calcelAutoSending(){
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length == 0) {
                    this.$message.warning("请选择论文");
                    return;
                }
                const loading = this.$loading({
                        lock: true,
                        text: '正在取消',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                })
                this.$api.sendingP2p.postCancalAutoSending(paperIds)
                        .then(res => {
                            loading.close();
                            if (res.data.code == 200) {
                                this.$message.success("取消成功！");
                                this.getP2pPaper();
                            } else {
                                this.$message.warning("取消失败!");
                            }
                        }).catch(err => {
                            loading.close();
                            this.$message.warning("服务器维护！");
                            console.log(err)
                        })
            },
            acceptedMatchHandle(){
                let params = {
                    matchType: 'out',
                    accept: '',
                    frequency: '',
                    quality:'',
                    // responsibility:'',
                    // singularity:'',
                    // mean:'',
                    // standard_deviation:'',
                    isSpare:0,
                    isSecondMajorr:0,
                    isUsed:1,
                    resultIds: null
                };
                this.match(params);
            },
            SpareMatchHandle(){
                let params = {
                    matchType: 'out',
                    accept: '',
                    frequency: '',
                    quality: '',
                    // responsibility:'',
                    // singularity:'',
                    // mean:'',
                    // standard_deviation:'',
                    isSpare:1,
                    isSecondMajor:0,
                    isUsed:0,
                    resultIds: null
                };
                this.match(params);
            },

            // 导出送审记录
            exportSending() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length == 0) {
                    this.$message.warning("请选择论文");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '导出中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let idInfos = [];
                selectRecords.forEach((item) => {
                    idInfos.push(item.paperId);
                })
                let params = {
                    paperIds: idInfos.join(',')
                }
                this.$api.sendingP2p.generateSendingTable(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let p = {
                                path: res.data.data
                            }
                            this.$api.reviewCheck.getTables(p)
                                .then(res1 => {
                                    const blob = new Blob([res1.data], {
                                        type: 'Application/vnd.ms-excel;charset=utf-8'
                                    });
                                    let url = window.URL || window.webkitURL;
                                    const href = url.createObjectURL(blob);
                                    let downloadElement = document.createElement('a');
                                    downloadElement.href = href;
                                    downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                                    document.body.appendChild(downloadElement);
                                    downloadElement.click(); //点击下载
                                    document.body.removeChild(downloadElement); //下载完成移除元素
                                    window.URL.revokeObjectURL(href); //释放掉blob对象
                                    loading.close();
                                }).catch(err => {
                                    this.$message.warning("导出excel错误！");
                                })
                        }
                    })
                    .catch(err => {
                        this.$message.warning("导出评审记录错误");
                    })
            },
            //导出送审过程表
            exportSendingDetail() {
              let selectRecords = this.$refs.paperTable.getCheckboxRecords();
              if (selectRecords.length == 0) {
                this.$message.warning("请选择论文");
                return;
              }
              const loading = this.$loading({
                lock: true,
                text: '导出中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              let idInfos = [];
              selectRecords.forEach((item) => {
                idInfos.push(item.paperId);
              })
              let params = {
                paperIds: idInfos.join(',')
              }
              this.$api.sendingP2p.generateSendingDetailTable(params)
                  .then(res => {
                    if (res.data.code == 200) {
                      let p = {
                        path: res.data.data
                      }
                      this.$api.reviewCheck.getTables(p)
                          .then(res1 => {
                            const blob = new Blob([res1.data], {
                              type: 'Application/vnd.ms-excel;charset=utf-8'
                            });
                            let url = window.URL || window.webkitURL;
                            const href = url.createObjectURL(blob);
                            let downloadElement = document.createElement('a');
                            downloadElement.href = href;
                            downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                            loading.close();
                          }).catch(err => {
                        this.$message.warning("导出excel错误！");
                      })
                    }
                  })
                  .catch(err => {
                    this.$message.warning("导出送审过程错误");
                  })
            },
            getFileName(str) {
                return str.substr(str.indexOf('\\') + 1)
            },
            //获取送审单位
            async getInstitution() {
                // let params = {
                //     isFiled : 0
                // }
                let param = new URLSearchParams();
                param.append("isFiled", 0);
                await this.$api.schoolCustom.getSendingInstitution(param)
                    .then(res => {
                        console.log(res);
                        this.institutionList = res.data.data;
                        console.log("getinstitutionlist", this.institutionList);
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            //获取送审单位的批次
            async getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId);
                param.append("isFiled",0);
                await this.$api.sendingP2p.getBatch(param)
                    .then(res => {
                        console.log(res.data.data)
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                        console.log(err)
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                this.search.batchId = '';
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            handleFileAddInstitution(val){
                this.fileAddInfo.batchId = '';
                if (val == '') {
                    this.fileAddInfo.batchShow = true;
                    return;
                }
                this.fileAddInfo.batchShow = false;
                this.getBatch(val);
            },
            //查看单篇论文的送审情况
            handleSingleCondition(paperId, batchId, paperName) {
                this.$router.push({
                    name: 'sendRecord',
                    params: {
                        id: paperId
                    }
                });
            },
            // 下载论文原件
            downloadPaper() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
            },
            // 修改截止时间
            editDeadline() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                this.editPaper = {
                    paperIds: '',
                    endData: '',
                    sendingNumber: '',
                    mappingMajor: '',
                    mappingSecondMajor:'',
                    reward:''
                }
                console.log(this.editPaper);
                this.editDeadlineModal = true;
            },
            cfmEdit() {
                if (this.editPaper.endData == '' && this.editPaper.sendingNumber == '' && this.editPaper.mappingMajor =='' && this.editPaper.mappingSecondMajor == '' && this.editPaper.reward == '') {
                    this.$message.warning("至少修改一项");
                    return;
                }
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let params = {
                    paperIds: paperIds.join(','),
                    endData: this.dateFormat(this.editPaper.endData),
                    sendingNumber: this.editPaper.sendingNumber,
                    mappingMajor: this.editPaper.mappingMajor,
                    mappingSecondMajor: this.editPaper.mappingSecondMajor,
                    reward: this.editPaper.reward
                }
                this.$api.sendingP2p.postModifyConditions(params)
                    .then(res => {
                        console.log(res)
                        if (res.data.code == 200) {
                            this.$message.success("修改成功");
                            this.resetEditPaper();
                            this.editDeadlineModal = false;
                        }else {
                            this.$message.warning(res.data.msg);
                            this.resetEditPaper();
                        }
                    }).catch(err => {
                        this.$message.warning("修改截止日期错误");
                        this.resetEditPaper();
                    })
            },
            autoSendingHandle(row){
                let params = {
                    paperId:row.paperId
                };
                function changeTime(date){
                    if(!date || date.length < 19) return date;
                    return date.substr(0,19).replace('T',' ');
                }
                this.$api.sendingP2p.getAutoDetail(params)
                .then(res=>{
                    if(res.data.data) this.autoSendingDetail = res.data.data;
                    else this.autoSendingDetail = {
                        startTime:'',
                        endTime:'',
                        days:''
                    }
                    this.autoSendingDetail.startTime = changeTime(this.autoSendingDetail.startTime)
                    this.autoSendingDetail.endTime = changeTime(this.autoSendingDetail.endTime)
                })
            },
            getSingleSendingRule(paperId) {
              let param = {
                paperId: paperId
              };
              this.$api.sendingP2p.getSingleSendingRule(param)
                  .then(res => {
                    this.sendingRules = res.data.data.map(item => ({
                      'paperId': paperId,
                      "paperFilterId": item.paperFilterId,
                      "universityLevel": item.universityLevel ? item.universityLevel.split('、') : [],
                      "expertType": item.expertType ? item.expertType.split('、') : [],
                      "expertTitle": item.expertTitle ? item.expertTitle.split('、') : [],
                      "debarb": item.debarb,
                      "appoint": item.appoint,
                      "sendingNum": item.sendingNum,
                      "localSendingNum": item.localSendingNum,
                      "expertReviewNum": item.expertReviewNum,
                      "pattern": item.pattern,
                      "debarbUniversity": item.debarbUniversity,
                      "appointUniversity": item.appointUniversity,
                      "mappingMajor": item.mappingMajor,
                      "mappingSecondMajor": item.mappingSecondMajor,
                      "majorGrade": item.majorGrade ? item.majorGrade.split('、') : [],
                      "debarbExperts": item.debarbExperts,
                      "appointRegion": item.appointRegion ? item.appointRegion.split('、') : [],
                      "debarbRegion": item.debarbRegion ? item.debarbRegion.split('、') : [],
                      "universityRanking": item.ranking ? item.ranking.split('-') : ['', ''],
                    }));
                  })
                  .catch(err => {
                    // 错误处理逻辑
                    console.error("Error fetching sending rule:", err);
                  });
            },
          resetRules() {
                // this.sendingRule = {
                //     'paperId': '',
                //     'paperFilterId':'',
                //     "universityLevel": [],
                //     "supervisorType": [],
                //     "expertTitle": [],
                //     "debarb": "0",
                //     "appoint": "0",
                //     "sendingNum": "2",
                //     "localSendingNum": "0",
                //     "expertReviewNum": "2",
                //     "debarbUniversity": "",
                //     "appointUniversity": "",
                //     "appointRegion": '',
                //     "debarbRegion": '',
                //     "debarbExperts": '',
                //     "universityRanking": ['', ''],
                // }
                this.sendingRules = []
            },
            editSendingRuleHandle(paperId) {
                this.resetRules();
                this.getSingleSendingRule(paperId);
                this.editRuleModal = true;
            },
            cfmEditSingleRule() {
                let params = this.sendingRules.map(rule => ({
                    'paperId': rule.paperId,
                    'paperFilterId': rule.paperFilterId,
                    "universityLevel": rule.universityLevel.join('、'),
                    "expertType": rule.expertType.join('、'),
                    "expertTitle": rule.expertTitle.join('、'),
                    "appointRegion": rule.appointRegion ? rule.appointRegion.join('、') : '',
                    "debarbRegion": rule.debarbRegion ? rule.debarbRegion.join('、') : '',
                    "ranking": rule.universityRanking[0] && rule.universityRanking[1] ? rule.universityRanking.join('-') : '',
                    "debarb": rule.debarb,
                    "appoint": rule.appoint,
                    "sendingNum": rule.sendingNum,
                    "localSendingNum": rule.localSendingNum,
                    "expertReviewNum": rule.expertReviewNum,
                    "pattern": rule.pattern,
                    "debarbUniversity": rule.debarbUniversity,
                    "appointUniversity": rule.appointUniversity,
                    "mappingMajor": rule.mappingMajor,
                    "mappingSecondMajor": rule.mappingSecondMajor,
                    "debarbExperts": rule.debarbExperts,
                    "majorGrade": rule.majorGrade.join('、')
                }));
                this.$api.sendingP2p.postModifyCondition(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("修改成功");
                            this.editRuleModal = false;
                        } else {
                            this.$message.warning(res.data.msg);
                        }
                    }).catch(err => {
                        this.editRuleModal = false;
                    })
            },
            // 短轮询：匹配
            getMatchCount(){
              this.$api.sendingP2p.queryMatchProcess()
                  .then(res => {
                    if (res.data.code = 200){
                      console.log(res.data);
                      this.matchText = "正在匹配中 "+ res.data.data + "/" + this.paperLength
                      this.matchLoading.setText(this.matchText)
                    }
                  }).catch(err => {
                    this.$message.warning("服务器维护")
              })
            },
            // 短轮询：邮件
            getSendingCount(){
              this.$api.sendingP2p.querySendingProcess()
                  .then(res => {
                    if (res.data.code = 200){
                      console.log(res.data);
                      this.sendText = "正在发送中 "+ res.data.data + "/" + this.paperLength
                      this.sendLoading.setText(this.sendText)
                    }
                  }).catch(err => {
                    this.$message.warning("服务器维护")
              })
            },
            // 修改论文信息
            editNoteHandle(row) {
                this.paperInfo.paperId = row.paperId;
                this.paperInfo.note = row.note;
                this.editNoteModal = true;
            },
            cfmEditNote(){
                let params = {
                    paperId:this.paperInfo.paperId,
                    note:this.paperInfo.note
                }
                this.$api.sendingP2p.setPaperNote(params)
                .then(res=>{
                    if(res.data.code == 200){
                        this.$message.success("添加成功！");
                        this.editNoteModal = false;
                        this.getP2pPaper();
                    }
                })
            },
            downloadSendingRule() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(','),
                };
                this.$api.sendingP2p.downloadPaperCondition(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            let p = {
                                path: res.data.data
                            }
                            this.$api.reviewCheck.getTables(p)
                                .then(res1 => {
                                    const blob = new Blob([res1.data], {
                                        type: 'Application/vnd.ms-excel;charset=utf-8'
                                    });
                                    let url = window.URL || window.webkitURL;
                                    const href = url.createObjectURL(blob);
                                    let downloadElement = document.createElement('a');
                                    downloadElement.href = href;
                                    downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                                    document.body.appendChild(downloadElement);
                                    downloadElement.click(); //点击下载
                                    document.body.removeChild(downloadElement); //下载完成移除元素
                                    window.URL.revokeObjectURL(href); //释放掉blob对象
                                    loading.close();
                                }).catch(err => {
                                    this.$message.warning("导出excel错误！");
                                })
                        }
                    })
                    .catch(err => {
                        this.$message.warning("导出评审记录错误");
                    })

            },
            downloadSendingRuleSpecial() {
              let selectRecords = this.$refs.paperTable.getCheckboxRecords();
              if (selectRecords.length <= 0) {
                this.$message.warning('请选择论文！');
                return;
              }
              const loading = this.$loading({
                lock: true,
                text: '下载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              let paperIds = [];
              selectRecords.forEach((item) => {
                paperIds.push(item.paperId);
              });
              let param = {
                paperIds: paperIds.join(','),
              };
              this.$api.sendingP2p.downloadPaperConditionSpecial(param)
                  .then(res => {
                    if (res.data.code == 200) {
                      let p = {
                        path: res.data.data
                      }
                      this.$api.reviewCheck.getTables(p)
                          .then(res1 => {
                            const blob = new Blob([res1.data], {
                              type: 'Application/vnd.ms-excel;charset=utf-8'
                            });
                            let url = window.URL || window.webkitURL;
                            const href = url.createObjectURL(blob);
                            let downloadElement = document.createElement('a');
                            downloadElement.href = href;
                            downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                            loading.close();
                          }).catch(err => {
                        this.$message.warning("导出excel错误！");
                      })
                    }
                  })
                  .catch(err => {
                    this.$message.warning("导出评审记录错误");
                  })

            },
            uploadSendingRule() {
                this.uploadSendingRuleModel = true;
            },
            uploadSendingRuleSpecial() {
              this.uploadSendingRuleSpecialModel = true;
            },
            uploadFile() {
                this.uploadFileModel = true;
            },
            uploadSendingResult() {
              this.uploadSendingResultModel = true;
            },
            uploadSendingDetail() {
              this.uploadSendingDetailModel = true;
            },
            cfmUploadFile(){
                if (this.$refs.paperFileInfo.uploadFiles.length < 1) {
                    this.$message.warning("请选择文件");
                    return;
                }
                if (this.fileAddInfo.batchId === '' ) {
                    this.$message.warning("请选择批次");
                    return;
                }
                let file = this.$refs.paperFileInfo.uploadFiles[0].raw;
                let params = {
                    file:file,
                    batchId: this.fileAddInfo.batchId
                }
                let param = new FormData();
                // JSON.stringify(this.fileAddInfo.batchId)
                param.append("batchId", JSON.stringify(this.fileAddInfo.batchId));
                param.append("file", file);

                // console.log(param.toString())
                let loading = this.$loading({
                    lock: true,
                    text: '正在上传',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.sendingP2p.replacePaperFile(param)
                    .then(res => {
                        if (res.data.code == 200) {
                            console.log(res)
                            this.$message.success("修改成功");
                            this.uploadFileModel = false;
                        } else {
                            console.log(res)
                            this.$message.warning(res.data.msg);
                        }
                        loading.close();
                    }).catch(err => {
                    // this.editRuleModal = false;
                    this.$message.warning("上传失败");
                    loading.close();
                })
            },
            cfmUploadSendingRule() {
                if (this.$refs.paperInfo.uploadFiles.length < 1) {
                    this.$message.warning("请选择审核信息");
                    return;
                }
                var xlsFile = this.$refs.paperInfo.uploadFiles[0];

                const loading = this.$loading({
                    lock: true,
                    text: '正在上传',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var reader = new FileReader(); //读取文件内容
                reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
                reader.onload = (e) => {
                    console.log(this.batchId);
                    var dd = e.target.result;
                    var workbook = XLSX.read(dd, {
                        type: 'buffer'
                    });
                    var sheetname = workbook.SheetNames[0]
                    var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
                    var paperDetails = [];
                    for (let i in tables) {
                        let row = tables[i];
                        var paps = {
                            'paperId': typeof (row["论文id"]) == 'undefined' ? '' : row[
                                "论文id"],
                            "universityLevel": typeof (row["单位类型"]) == 'undefined' ? '' : row[
                                "单位类型"],
                            "expertType": typeof (row["专家类型"]) == 'undefined' ? '' : row[
                                "专家类型"],
                            "expertTitle": typeof (row["专家职称"]) == 'undefined' ? '' : row[
                                "专家职称"],
                            "debarb": typeof (row["是否回避院校"]) == 'undefined' ? '' : row[
                                "是否回避院校"],
                            "appoint": typeof (row["是否指定院校"]) == 'undefined' ? '' : row[
                                "是否指定院校"],
                            "sendingNum": typeof (row["送审意见数"]) == 'undefined' ? '' : row[
                                "送审意见数"],
                            "localSendingNum": typeof (row["本校意见数"]) == 'undefined' ? '' : row[
                                "评阅书ID"],
                            "expertReviewNum": typeof (row["专家最大接审数"]) == 'undefined' ? '' : row[
                                "专家最大接审数"],
                            "pattern": typeof (row["送审模式"]) == 'undefined' ? '' : row[
                                "评阅书ID"],
                            "debarbUniversity": typeof (row["回避院校"]) == 'undefined' ? '' : row[
                                "回避院校"],
                            "appointUniversity": typeof (row["指定院校"]) == 'undefined' ? '' : row[
                                "指定院校"],
                            "mappingMajor": typeof (row["映射一级学科"]) == 'undefined' ? '' : row[
                                "映射一级学科"],
                            "mappingSecondMajor": typeof (row["映射二级学科"]) == 'undefined' ? '' : row[
                                "映射二级学科"],
                            "majorGrade": typeof (row["学科匹配等级"]) == 'undefined' ? '' : row[
                                "学科匹配等级"],
                            "ranking": typeof (row["院校排名"]) == 'undefined' ? '' : row[
                                "院校排名"],
                            "debarbRegion": typeof (row["回避地区"]) == 'undefined' ? '' : row[
                                "回避地区"],
                            "appointRegion": typeof (row["指定地区"]) == 'undefined' ? '' : row[
                                "指定地区"],
                            "debarbExperts": typeof (row["回避指定专家"]) == 'undefined' ? '' : row[
                                "回避指定专家"]
                        };
                        console.log(11111111)
                        console.log(paps)
                        paperDetails.push(paps);
                    };
                    this.$api.sendingP2p.postModifyCondition(paperDetails)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("修改成功");
                                this.uploadSendingRuleModel = false;
                            } else {
                                this.$message.warning(res.data.msg);
                            }
                            loading.close();
                        }).catch(err => {
                            // this.editRuleModal = false;
                            this.$message.warning("上传失败");
                            loading.close();
                        })

                }
            },
            cfmUploadSendingRuleSpecial() {
              if (this.$refs.paperInfo.uploadFiles.length < 1) {
                this.$message.warning("请选择审核信息");
                return;
              }
              var xlsFile = this.$refs.paperInfo.uploadFiles[0];

              const loading = this.$loading({
                lock: true,
                text: '正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              var reader = new FileReader(); //读取文件内容
              reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
              reader.onload = (e) => {
                console.log(this.batchId);
                var dd = e.target.result;
                var workbook = XLSX.read(dd, {
                  type: 'buffer'
                });
                var sheetname = workbook.SheetNames[0]
                var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
                var paperDetails = [];
                for (let i in tables) {
                  let row = tables[i];
                  var paps = {
                    'paperFilterId': typeof (row["送审规则id"]) == 'undefined' ? '' : row[
                        "送审规则id"],
                    'paperId': typeof (row["论文id"]) == 'undefined' ? '' : row[
                        "论文id"],
                    "universityLevel": typeof (row["单位类型"]) == 'undefined' ? '' : row[
                        "单位类型"],
                    "expertType": typeof (row["专家类型"]) == 'undefined' ? '' : row[
                        "专家类型"],
                    "expertTitle": typeof (row["专家职称"]) == 'undefined' ? '' : row[
                        "专家职称"],
                    "debarb": typeof (row["是否回避院校"]) == 'undefined' ? '' : row[
                        "是否回避院校"],
                    "appoint": typeof (row["是否指定院校"]) == 'undefined' ? '' : row[
                        "是否指定院校"],
                    "sendingNum": typeof (row["送审意见数"]) == 'undefined' ? '' : row[
                        "送审意见数"],
                    "localSendingNum": typeof (row["本校意见数"]) == 'undefined' ? '' : row[
                        "评阅书ID"],
                    "expertReviewNum": typeof (row["专家最大接审数"]) == 'undefined' ? '' : row[
                        "专家最大接审数"],
                    "pattern": typeof (row["送审模式"]) == 'undefined' ? '' : row[
                        "评阅书ID"],
                    "debarbUniversity": typeof (row["回避院校"]) == 'undefined' ? '' : row[
                        "回避院校"],
                    "appointUniversity": typeof (row["指定院校"]) == 'undefined' ? '' : row[
                        "指定院校"],
                    "mappingMajor": typeof (row["映射一级学科"]) == 'undefined' ? '' : row[
                        "映射一级学科"],
                    "mappingSecondMajor": typeof (row["映射二级学科"]) == 'undefined' ? '' : row[
                        "映射二级学科"],
                    "majorGrade": typeof (row["学科匹配等级"]) == 'undefined' ? '' : row[
                        "学科匹配等级"],
                    "ranking": typeof (row["院校排名"]) == 'undefined' ? '' : row[
                        "院校排名"],
                    "debarbRegion": typeof (row["回避地区"]) == 'undefined' ? '' : row[
                        "回避地区"],
                    "appointRegion": typeof (row["指定地区"]) == 'undefined' ? '' : row[
                        "指定地区"],
                    "debarbExperts": typeof (row["回避指定专家"]) == 'undefined' ? '' : row[
                        "回避指定专家"],
                    "reward": typeof (row["报酬金额"]) == 'undefined' ? '' : row[
                        "报酬金额"]
                  };
                  console.log(11111111)
                  console.log(paps)
                  paperDetails.push(paps);
                };
                this.$api.sendingP2p.postModifyConditionSpecial(paperDetails)
                    .then(res => {
                      if (res.data.code == 200) {
                        this.$message.success("修改成功");
                        this.uploadSendingRuleSpecialModel = false;
                        this.$refs.paperInfo.clearFiles();
                      } else {
                        this.$message.warning(res.data.msg);
                        this.$refs.paperInfo.clearFiles();
                      }
                      loading.close();
                    }).catch(err => {
                  // this.editRuleModal = false;
                  this.$message.warning("上传失败");
                  this.$refs.paperInfo.clearFiles();
                  loading.close();
                })

              }
            },
            cfmUploadSendingResult() {
              if (this.$refs.resultInfo.uploadFiles.length < 1) {
                this.$message.warning("请选择审核信息");
                return;
              }
              var xlsFile = this.$refs.resultInfo.uploadFiles[0];

              const loading = this.$loading({
                lock: true,
                text: '正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              var reader = new FileReader(); //读取文件内容
              reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
              reader.onload = (e) => {
                console.log(this.batchId);
                var dd = e.target.result;
                var workbook = XLSX.read(dd, {
                  type: 'buffer'
                });
                var sheetname = workbook.SheetNames[0]
                var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
                var resultDetails = [];
                for (let i in tables) {
                  let row = tables[i];
                  var result = {
                    'paperId': typeof (row["论文id"]) == 'undefined' ? '' : row[
                        "论文id"],
                    "batchName": typeof (row["批次名称"]) == 'undefined' ? '' : row[
                        "批次名称"],
                    "oldId": typeof (row["论文编号"]) == 'undefined' ? '' : row[
                        "论文编号"],
                    "expertName": typeof (row["专家姓名"]) == 'undefined' ? '' : row[
                        "专家姓名"],
                    "expertOrg": typeof (row["专家单位"]) == 'undefined' ? '' : row[
                        "专家单位"],
                    "mail": typeof (row["专家邮箱"]) == 'undefined' ? '' : row[
                        "专家邮箱"],
                    "mobile": typeof (row["专家电话"]) == 'undefined' ? '' : row[
                        "专家电话"],
                    "score": typeof (row["匹配分值"]) == 'undefined' ? '' : row[
                        "匹配分值"],
                  };
                  resultDetails.push(result);
                };
                this.$api.sendingP2p.postSendingResult(resultDetails)
                    .then(res => {
                      // 不论成功还是失败，都执行以下操作
                      this.handleResult(res.data);
                      loading.close();
                    }).catch(err => {
                  // 如果请求本身失败，也执行相同的操作
                  this.handleResult({ code: 500, msg: "网络错误或请求失败，请稍后再试" });
                  loading.close();
                });
              }
            },
          cfmUploadSendingDetail() {
            if (this.$refs.resultInfo.uploadFiles.length < 1) {
              this.$message.warning("请选择审核信息");
              return;
            }
            var xlsFile = this.$refs.resultInfo.uploadFiles[0];

            const loading = this.$loading({
              lock: true,
              text: '正在上传',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            var reader = new FileReader(); //读取文件内容
            reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
            reader.onload = (e) => {
              console.log(this.batchId);
              var dd = e.target.result;
              var workbook = XLSX.read(dd, {
                type: 'buffer'
              });
              var sheetname = workbook.SheetNames[0]
              var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
              var resultDetails = [];
              for (let i in tables) {
                let row = tables[i];
                var result = {
                  'paperId': typeof (row["论文id"]) == 'undefined' ? '' : row[
                      "论文id"],
                  "expertName": typeof (row["专家姓名"]) == 'undefined' ? '' : row[
                      "专家姓名"],
                  "expertOrg": typeof (row["专家单位"]) == 'undefined' ? '' : row[
                      "专家单位"],
                  "mail": typeof (row["专家邮箱"]) == 'undefined' ? '' : row[
                      "专家邮箱"],
                  "urgeText": typeof (row["催审记录"]) == 'undefined' ? '' : row[
                      "催审记录"],
                };
                resultDetails.push(result);
              };
              this.$api.sendingP2p.postSendingDetail(resultDetails)
                  .then(res => {
                    // 不论成功还是失败，都执行以下操作
                    this.handleResult(res.data);
                    loading.close();
                  }).catch(err => {
                // 如果请求本身失败，也执行相同的操作
                this.handleResult({ code: 500, msg: "网络错误或请求失败，请稍后再试" });
                loading.close();
              });
            }
          },
            handleResult(res) {
              // 根据返回的code决定显示哪种消息
              if (res.code == 200) {
                this.$message.success(res.msg || "上传成功");
              } else {
                this.$message.warning(res.msg || "上传失败");
              }
              // 关闭弹窗并清除文件列表
              this.uploadSendingResultModel = false;
              this.$refs.resultInfo.clearFiles();
            },
            dateFormat(time) {
                if (time == '') return '';
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
            // 发送催审邮件
            sendUrgeMail() {
                //获取已选中的论文
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '正在发送',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(','),
                };

                this.$api.sendingP2p. sendUrgeMail(param)
                    .then(res => {
                        loading.close();
                        if (res.data.code == 200) {
                            this.$message.success("发送成功");
                        } else {
                            this.$message.warning("发送失败");
                        }
                    }).catch(err => {
                        this.$message.warning("发送失败");
                    })
            },
            //发送邀审信息
            sendEmailHandle(paperId) {
                //获取已选中的论文
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                this.paperLength = selectRecords.length;
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    // this.refresh();
                    return;
                }
                // this.sendText = "正在发送中 " + this.count + "/" + this.paperLength
                this.sendLoading = Loading.service({fullscreen: true, text: "正在发送"})
                // this.sendLoading.setText(this.sendText)
                // this.sendRefresh = setInterval(this.getSendingCount, 1000);
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(','),
                };
                this.$api.sendingP2p.getSendEmail(param)
                    .then(res => {
                        this.sendLoading.close();
                        // this.count = 0;
                        if (res.data.code == 200) {
                            this.$message.success("发送成功");
                            // clearInterval(this.sendRefresh);
                            this.refresh();

                        } else {
                            // this.process = 99;
                            // this.sendProcessTitle = '发送失败';
                            this.$message.warning("发送失败");
                            // clearInterval(this.sendRefresh);
                            this.refresh();
                        }
                    }).catch(err => {
                        this.sendLoading.close();
                        this.$message.warning("发送失败");
                        // clearInterval(this.sendRefresh);
                        this.refresh();
                    })

                // this.$axios.post("/sending_paper/send_email", param)
                //     .then(res => {
                //         // clearInteral(this.sendTimer);
                //         loading.close();
                //         this.showClose = true;
                //         if (res.data.code == 1) {
                //             this.process = 100;
                //             this.sendProcessTitle = '发送成功';
                //             this.$message.success("发送成功");
                //         } else {
                //             this.process = 99;
                //             this.sendProcessTitle = '发送失败';
                //             this.$message.warning("发送失败");
                //         }
                //     })
                //     .catch(err => {
                //         loading.close();
                //         this.$message.warning("服务器维护！");
                //     });
            },
            //获取发送精度
            getProcess() {
                this.$axios.get('sending_paper/progress')
                    .then(res => {
                        this.process = res.data.data;
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            batchSearch() {

                this.page.pageIndex = 1;
                this.getP2pPaper();
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    // student: '',
                    studentNum: '',
                    supervisor: '',
                    student: '',
                    state: '',
                    paperType: '',
                    major: '',
                    haveNote:'',
                    sendingType:''
                };
            },
            resetEditPaper(){
              console.log(1)
              this.editPaper = {
                paperIds: '',
                endData: '',
                sendingNumber: '',
                mappingMajor: '',
                mappingSecondMajor:'',
                reward:''
              };
              console.log(this.editPaper)
              this.$nextTick(() => {
                // 确保DOM更新完成后执行的代码

              });
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                this.page.pageIndex = currentPage;
                this.page.pageSize = pageSize;
                this.getP2pPaper();
            },
            firstPage() {
                this.page.pageIndex = 1;
                this.getP2pPaper();
            },
            endPage() {
                this.page.pageIndex = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getP2pPaper();
            },
            //获取选中的论文
            getSelectEvent() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
            },
            footerMethod({
                columns,
                data
            }) {
                const sums = [];
                let finishedNum = 0,
                    reviewNum = 0,
                    waitingNum = 0,
                    expertNumber = 0,
                    pendingNum = 0,
                    sendingNum = 0;
                data.forEach(item => {
                    finishedNum += item.finishedNum;
                    reviewNum += item.reviewNum;
                    waitingNum += item.waitingNum;
                    sendingNum += item.sendingNum;
                    pendingNum += item.pendingNum;
                    expertNumber += item.expertNumber;
                })
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('总计')
                    } else {
                        switch (column.property) {
                            case 'waitingNum':
                                sums.push(waitingNum + '/' + expertNumber);
                                break
                            case 'sendingNum':
                                sums.push(sendingNum + '/' + expertNumber);
                                break
                            case 'reviewNum':
                                sums.push(reviewNum + '/' + expertNumber);
                                break
                            case 'finishedNum':
                                sums.push(finishedNum + '/' + expertNumber);
                                break
                            case 'pendingNum':
                                sums.push(pendingNum + '/' + expertNumber);
                                break
                            default:
                                sums.push('-');
                        }
                    }
                })
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            headerCellClassName({
                column,
                columnIndex
            }) {
                return 'vxe-table-thead'
            },
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            scroll(e) {
                // console.log('scroll', e.target.scrollTop)
            },
            async handleInstitutionChange(){
                await this.getInstitution();
                if(this.search.institutionId==""){
                    return;
                }
                let r = 0;
                console.log(this.institutionList)
                for (let i = 0; i < this.institutionList.length; i++) {
                    if (this.institutionList[i].institutionId == this.search.institutionId) {
                        r = 1;
                        break;
                    }
                }
                if (r == 0 ) {
                    // 没有单位信息，单位全部归档
                    this.resetHandle();
                    this.batchSearch();
                }
                if (r == 1) {
                    r = 0;
                    await this.getBatch(this.search.institutionId);
                    console.log(this.batchList);
                    for (let i = 0; i < this.batchList.length; i++) {
                        if (this.batchList[i].batchId == this.search.batchId) {
                            r = 1;
                            break;
                        }
                    }
                    if (r == 0) {
                        // 没有批次信息，批次全部归档
                        this.search.batchId = '';
                        this.batchSearch();
                    }
                }
            },
            // 是否显示下拉框
            isShowSelectOptions(isShowSelectOptions){
                if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
                if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
                if(!isShowSelectOptions) this.$refs.selectHaveNote.blur();
                if(!isShowSelectOptions) this.$refs.selectState.blur();
                if(!isShowSelectOptions) this.$refs.selectSendingType.blur();
                if(!isShowSelectOptions) this.$refs.selectStatus.blur();

            },
            async openWebSocket(){
              let token = await this.$api.user.getSession();
              token = token.data.data;
              let url = `wss://center.deepthinking.net.cn/ws/websocket/${token}`;
            //   let url = `ws://localhost:8082/ws/websocket/${token}`;
              this.ws = new WebSocket(url);
              this.ws.onopen = function(e)
              {
                console.log("websocket连接成功");
                console.log(e)
              };
              this.ws.onmessage = (evt)=>{
                let data = JSON.parse(evt.data);
                console.log(evt)
                console.log(data)
                console.log("websocket获取信息成功")
                this.count += 1;
                if (data == 1){
                  this.matchText = "正在匹配中 "+ this.count + "/" + this.paperLength
                  this.matchLoading.setText(this.matchText)
                  console.log(this.matchText)
                } else if(data == 2){
                  this.sendText = "正在发送中 "+ this.count + "/" + this.paperLength
                  this.sendLoading.setText(this.sendText)
                  console.log(this.sendText)
                }
              }
            }
        },
        //在页面离开时记录滚动位置
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
            next()
        },
        //进入该页面时，用之前保存的滚动位置赋值
        // beforeRouteEnter(to, from, next) {
        //     next(vm => {
        //         console.log(vm.scrollTop);
        //         console.log(vm.$refs)
        //     })
        // },
        components: {

        }
    }
</script>

<style scoped>
    .inputSelect {
        width: 120px;
    }

    .custom-margin {
      margin-right: 14px; /* 设置右侧间隔 */
    }

    .collapseInput {
        margin-top: 20px;
    }

    .searchBtn {
        padding: 20px 0px 0px 0px;
        text-align: center;
    }

    .box-card {
        padding-bottom: 0px !important;
    }

    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .form-line-item {
        width: 15em;
    }

    .el-form-item {
        margin-bottom: 10px !important;
    }

    .number {
        width: 45% !important;
    }

</style>
<style>
    .paperPageList .vxe-table--body-wrapper {
        height: calc(100vh - 384px);
    }

    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }

    .searchForm .el-form-item__label {
        background-color: #F5F7FA;
        color: #909399;
    }

    .modal-scrollable .vxe-modal--box {
      max-height: 80vh; /* 设置最大高度为视口高度的80% */
      overflow-y: auto; /* 当内容超出时，允许垂直滚动 */
    }

</style>